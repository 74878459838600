import React from "react";

const header = () => {
  return (
    <div className="header">
      <h1>Ozaukee Ice Center Facility Schedule</h1>
    </div>
  );
};

export default header;
