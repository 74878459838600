import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      Created with ReactJS by Brian Christensen for the Ozaukee Ice Center
    </div>
  );
};

export default Footer;
